import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, List, ListItem, IconButton } from '@mui/material';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { fetchPayment, removePayment } from '../services/api';
import { toast } from 'react-toastify';
import AddCardModal from './card';

const PaymentMethods = () => {
  const [addCardModalOpen, setAddCardModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    phoneNumber: '',
    email: ''
  });
  const [paymentMethods, setPaymentMethods] = useState([]);

  const openAddCardModal = () => {
    setAddCardModalOpen(true);
  };

  const closeAddCardModal = () => {
    setAddCardModalOpen(false);
  };

  const book = () => {
    closeAddCardModal();
    window.location.reload();
  };

  const fetchPaymentData = async () => {
    const response = await fetchPayment(localStorage.getItem("token"));
    setPaymentMethods(response.data.methods);
    setUserData({
      name: response.data.name,
      email: response.data.email,
      phoneNumber: response.data.number
    });
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  const handleRemovePayment = async (id) => {
    const response = await removePayment(localStorage.getItem("token"), id);
    window.location.reload();
    if (response.status !== 200) {
      toast.info("Error occurred");
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {/* Basic Information */}
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 4, p: 2 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Basic Information
              </Typography>
              <List>
                <ListItem>
                  <Typography variant="body1">Name: {userData.name}</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">Phone Number: {userData.phoneNumber}</Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Payment Methods */}
        <Grid item xs={12}>
          <Card sx={{ mb: 4, p: 2 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Payment Methods
              </Typography>
              <List>
                {paymentMethods.map((method) => (
                  <ListItem
                    key={method.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 1,
                    }}
                  >
                    <div>
                      <Typography variant="body1"><strong>Card Type:</strong> Credit Card</Typography>
                      <Typography variant="body2">Last 4 Digits: **** **** **** {method.card.last4}</Typography>
                      <Typography variant="body2">Expiration: {method.card.exp_month}/{method.card.exp_year}</Typography>
                    </div>
                    <IconButton color="error" onClick={() => handleRemovePayment(method.id)}>
                      <FaTrash />
                    </IconButton>
                  </ListItem>
                ))}
                {/* Conditionally render the "Add Payment Method" button */}
                {paymentMethods.length === 0 && (
                  <ListItem sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained" color="primary" startIcon={<FaPlus />} onClick={openAddCardModal}>
                      Add Payment Method
                    </Button>
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Add Card Modal */}
      {addCardModalOpen && (
        <AddCardModal
          closeModal={closeAddCardModal}
          token={localStorage.getItem("token")}
          book={book}
          isBooking={true}
        />
      )}
    </Container>
  );
};

export default PaymentMethods;
