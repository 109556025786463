import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { bookingStats } from '../services/api';

function Tracker() {
  const [data, setData] = useState({
    totalEarnings: 0,
    totalRides: 0,
    totalPayoutReceived: 0,
    totalPayoutPending: 0,
    monthlyEarnings: [],
    payoutReceived:0
  });
  
  // Function to fetch data from the API
  const bookingStatsFetch = async () => {
    try {
      const response = await bookingStats(localStorage.getItem("token"));
      const fetchedData = response.data;
      
      setData({
        totalEarnings: fetchedData.totalEarnings || 0,
        totalRides: fetchedData.totalCommissionsBookings || 0,
        totalPayoutReceived: fetchedData.totalPayoutReceived || 0,
        totalPayoutPending: fetchedData.totalPayoutPending || 0,
        monthlyEarnings: fetchedData.monthlyEarnings || [],
        payoutReceived:fetchedData.payoutReceived
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    bookingStatsFetch();
  }, []);

  return (
    <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" fontWeight="bold">Earnings Tracker</Typography>
      </Box>

      {/* Overview Section */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: '20px' }}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
               Overview
            </Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <Typography variant="body1" align="center">
                  <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 40 }} />
                  <br />
                  Total Earnings: ${data.totalEarnings}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="center">
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 40 }} />
                  <br />
                  Payout Received: ${data.payoutReceived}
                </Typography>
              </Grid>
         
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ padding: '20px' }}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              Payout Overview
            </Typography>
            <Grid container spacing={2} mt={2}>
              {/* <Grid item xs={6}>
                <Typography variant="body1" align="center">
                  <AttachMoneyIcon color="success" sx={{ fontSize: 40 }} />
                  <br />
                  Payout Received: ${data.payoutReceived}
                </Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  <DirectionsCarIcon color="secondary" sx={{ fontSize: 40 }} />
                  <br />
                  {data.totalRides} Rides Completed
                </Typography>
              </Grid>
        
            </Grid>
          </Card>
        </Grid> 
      </Grid>

      {/* Bar Chart Section */}
      <Card sx={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Monthly Earnings
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data.monthlyEarnings}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="earnings" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </Box>
  );
}

export default Tracker;
