import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/logo.png'; // Replace with the actual path to your logo
import { signupPartner } from '../services/api';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  textTransform: 'none',
  backgroundColor: '#004AAD',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#003080',
  },
}));

const Signup = ({ onNext }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    phoneNumber: '',
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const phoneRegex = /^\+\d{1,3}\s?\d{4,14}(?:x\d+)?$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate email and phone on change
    if (name === 'email') {
      setErrors((prev) => ({
        ...prev,
        email: emailRegex.test(value) ? '' : 'Invalid email format',
      }));
    } else if (name === 'phoneNumber') {
      setErrors((prev) => ({
        ...prev,
        phoneNumber: phoneRegex.test(value)
          ? ''
          : 'Phone number must include country code (e.g., +1 1234567890)',
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (!errors.email && !errors.phoneNumber) {
        console.log('Form Submitted:', formData);

        const resp = await signupPartner(formData);
        if(resp.status ===200){
          // alert("done");
                 onNext(formData); // Pass the formData to the parent

        }else{
          alert(resp.data.message);
        }

        // onNext(formData); // Pass the formData to the parent
      } else {
        alert(errors);
        
        console.error('Validation errors:', errors);
      }
    } catch (error) {
      alert(error.message);
      console.error('Submission failed:', error);
    }
  };

  return (
    <Box sx={{ width: '70%' }}>
      <img src={logo} alt="GoBlu-EV Logo" height={50} width={180} style={{ marginBottom: 24 }} />
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Create your account!
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Get unlimited discounts on your ride bookings
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Organization Name"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Organization Email ID"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Phone Number (with country code)"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          type="tel"
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
        />
        <StyledButton
          onClick={handleSubmit}
          disabled={
            !formData.email || !formData.phoneNumber || !!errors.email || !!errors.phoneNumber
          }
        >
          Continue
        </StyledButton>
      </Box>

      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Already have an account?{' '}
        <Link href="/" underline="hover">
          Login here
        </Link>
      </Typography>
    </Box>
  );
};

export default Signup;
